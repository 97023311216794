import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import userReducer from "./slices/user";
import jobsReducer from "./slices/jobs";
import plannerReducer from "./slices/planner";
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [
    'user'
 ],
};

const rootReducer = combineReducers({
  user: userReducer,
  jobs: jobsReducer,
  planner: plannerReducer
});

export { rootPersistConfig, rootReducer };
