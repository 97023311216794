// @mui
import { Typography, Stack } from "@mui/material";
// components
import Image from "../../components/image";
//
import {
  StyledRoot,
  StyledSectionBg,
  StyledSection,
  StyledContent,
} from "./styles";

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title }: Props) {
  return (
    <StyledRoot>
      <Image
        sx={{
          zIndex: 9,
          position: "absolute",
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
          width: 300,
        }}
        src={illustration || "/logo/logo.png"}
      />

      <StyledSection>
        <Typography variant="h3" sx={{ maxWidth: 480, textAlign: "center" }}>
          {title || "Hi, Welcome back"}
        </Typography>

        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={illustration || "/assets/illustrations/truck.png"}
          sx={{
            maxWidth: 620,
            animation: "up-down 2s ease-in-out infinite alternate-reverse both",
          }}
        />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
