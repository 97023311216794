import { createSlice } from "@reduxjs/toolkit";
// utils
import { axios } from "../../utils/axios";
import { accountAxios } from "../../utils/accountAxios";
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState: any = {
  isLoading: false,
  error: null,
  products: [],
  operators: [],
  vehicles: [],
  depots: [],
  filters:[],
  paymentFilters: [],
  productColours:[],
};

const slice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

     // GET PAYMENTS
     getPaymentsSuccess(state, action) {
      state.isLoading = false;
      state.payments = action.payload;
    },

    getOperatorSuccess(state, action) {
      state.isLoading = false;
      state.operators = action.payload;
    },


    getVehicleSuccess(state, action) {
      state.isLoading = false;
      state.vehicles = action.payload;
    },

    getDepotSuccess(state, action) {
      state.isLoading = false;
      state.depots = action.payload;
    },

    getFilterSuccess(state, action) {

      state.isLoading = false;
      state.filters = action.payload;
    },

    getPaymentFilterSuccess(state, action) {
  
      state.isLoading = false;
      state.paymentFilters = action.payload;
    },

    getProductColoursSuccess(state, action) {
  
      state.isLoading = false;
      state.productColours = action.payload;
    },


    // GET PRODUCT

    // CHECKOUT
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProducts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await accountAxios.get("/rest/api/account/job/product/all");
      dispatch(slice.actions.getProductsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOperators() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/rest/api/auth/job/operator/all");
      dispatch(slice.actions.getOperatorSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPayments() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await accountAxios.get("/rest/api/account/job/payments/all");
      dispatch(slice.actions.getPaymentsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVehicles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/rest/api/account/job/vehicle/all");
      dispatch(slice.actions.getVehicleSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function getFilters() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/rest/api/auth/user/configuration/job/filter");
      dispatch(slice.actions.getFilterSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function getPaymentFilters() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/rest/api/auth/user/configuration/payment/filter");
      dispatch(slice.actions.getPaymentFilterSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------


export function getProductColors() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/rest/api/account/job/product/colour/all");
      dispatch(slice.actions.getProductColoursSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

