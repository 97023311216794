import axios from "axios";
// config
import { HOST_API_KEY } from "../config";

// ----------------------------------------------------------------------

// function getLocalAccessToken() {
//   const accessToken = window.localStorage.getItem("accessToken");
//   return accessToken;
// }

// function getLocalRefreshToken() {
//   const refreshToken = window.localStorage.getItem("refreshToken");
//   return refreshToken;
// }

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: HOST_API_KEY,
  // validateStatus: () => true,
});

const refreshTokenCall = async () => {
  try {
    const response = await axiosInstance.get(
      "/rest/api/auth/user/refresh/token",
      {
        withCredentials: true,
      }
    );
    if (response && response.data.success) {
      window.location.reload();
    } else {
      // logout();
    }
    // return response.data;
  } catch (err: any) {
    // if (err.response.status == 403) {
    // logout();

    // return err.response.status;
    // }
  }
};
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) =>
//     Promise.reject(
//       (error.response && error.response.data) || "Something went wrong"
//     )
// );

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const config = error?.config;
//     if (error?.response?.status === 403 && !config?.sent) {
//       config.sent = true;

//       const result = await refreshTokenCall();
//       // if (result?.accessToken) {
//       //   config.headers = {
//       //     ...config.headers,
//       //     authorization: `Bearer ${result?.accessToken}`,
//       //   };
//       // }

//       return axios(config);
//     }
//     return Promise.reject(error);
//   }
// );

axiosInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    const result = await refreshTokenCall();
    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    return axios(originalRequest);
  }
  return Promise.reject(error);
});

// axiosInstance.interceptors.request.use(
//   (config: any) => {
//     const token = getLocalAccessToken();
//     if (token) {
//       config.headers["x-access-token"] = token;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// axiosInstance.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     const originalConfig = err.config;

//     if (err.response) {
//       // Access Token was expired
//       if (err.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;

//         try {
//           const rs = await refreshTokenCall();
//           const { accessToken } = rs?.data;
//           window.localStorage.setItem("accessToken", accessToken);
//           axiosInstance.defaults.headers.common["x-access-token"] = accessToken;

//           return axiosInstance(originalConfig);
//         } catch (_error) {
//           if (_error.response && _error.response.data) {
//             return Promise.reject(_error.response.data);
//           }

//           return Promise.reject(_error);
//         }
//       }

//       if (err.response.status === 403 && err.response.data) {
//         return Promise.reject(err.response.data);
//       }
//     }

//     return Promise.reject(err);
//   }
// );

export { axiosInstance as axios };
