import { createContext, useEffect, useReducer, ReactNode } from "react";
// utils
import { axios } from "../utils/axios";
import { ActionMap, AuthState, JWTContextType } from "../@types/authentication";

enum Types {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Register = "REGISTER",
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: any;
  };
  [Types.Login]: {
    user: any;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: any;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case "REGISTER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        axios.defaults.withCredentials = true;

        const response = await axios.get("/rest/api/auth/user");

        if (response && response.data) {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: response.data.data,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err: any) {
        console.error(err);

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post(
      "/rest/api/auth/user/login",
      {
        email,
        password,
      },
      { withCredentials: true }
    );
    const { success, token } = response.data;

    if (success != false) {
      const getUser = await getUserDetails();
      dispatch({
        type: Types.Login,
        payload: {
          user: getUser,
        },
      });
    } else {
      throw Error(JSON.stringify(response.data.message));
    }
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    const response = await axios.post("/api/account/register", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    dispatch({
      type: Types.Initial,
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });
    try {
      const response = await axios.get("/rest/api/auth/user/logout");
      if (response && response.status == 200) {
        dispatch({ type: Types.Logout });
      }
    } catch (err: any) {
      console.error(err);
      if (err?.response?.status == 403) {
        dispatch({ type: Types.Logout }); // this is not working
      } else {
        dispatch({ type: Types.Logout });
      }
    }
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  const refreshTokenCall = async () => {
    try {
      const response = await axios.get("/rest/api/auth/user/refresh/token", {
        withCredentials: true,
      });
      if (response && response.data.success) {
        window.location.reload();
      } else {
        logout();
      }
      // return response.data;
    } catch (err: any) {
      // if (err.response.status == 403) {
      logout();

      // return err.response.status;
      // }
    }
  };

  const getUserDetails = async () => {
    const response = await axios.get("/rest/api/auth/user");
    return response.data.data;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        refreshTokenCall,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
