import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import { axios } from "../../utils/axios";
import { UserManager, Profile } from "../../@types/user";
type UserState = {
  isLoading: boolean;
  error: boolean;
  userList: UserManager[];
  myProfile: null | Profile;
};

const initialState: UserState = {
  isLoading: false,
  error: false,
  userList: [],
  myProfile: null,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------
// Get All Users ::

export function getUserList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/rest/api/auth/user/all");
      if (response.data.success) {
        dispatch(slice.actions.getUserListSuccess(response.data.data));
      } else {
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfile() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/auth/user/profile");
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
