import { createSlice } from "@reduxjs/toolkit";
// utils
import { axios } from "../../utils/axios";
import { accountAxios } from "../../utils/accountAxios";
import { dispatch } from "../store";
import moment from "moment";
import { IDepots, PlannerState } from "../../@types/planner";
import { useAuthContext } from "src/auth/useAuthContext";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

const initialState: PlannerState = {
  depots: [],
  productTypes: [],
  pinColoursByProductType: [],
  jobRuns: [],
  unRoutedJobs: [],
  routedJobs: [],
  deliveredJobs: [],
  selectedRunJobs: [],
  operators: [],
  vehicles: [],
  isLoading: false,
  isAllocationRunLoading: false,
  isUnroutedLoading: false,
  isRoutedLoading: false,
  isDelieverdLoading: false,
  isSelectedRunLoading: false,
  error: null,
  filters: {
    unRoutedUpto: moment().format("YYYY-MM-DD"),
    allTheLatest: false,
    showRoutedFor: moment().format("YYYY-MM-DD"),
    selectedDepot: {
      depotID: -1,
      depotName: "All",
      latitude: "",
      longitude: "",
    },
    productType: "All",
  },
  selectedRun: null,
  delieveredOn: false,
  routedOn: false,
  unroutedOn: true,
  runDetail: null,
  isRunDetailLoading: false,
  isOptimiserLoading: false,
  isMandatoryLoading: false,
  showMessage: "",
  showMessageVariant: "",
  refreshPage: false,
  drawPolygon: false,
};

const slice = createSlice({
  name: "planner",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    getOperatorSuccess(state, action) {
      state.isLoading = false;
      state.operators = action.payload;
    },

    getVehicleSuccess(state, action) {
      state.isLoading = false;
      state.vehicles = action.payload;
    },

    startAllocationLoading(state) {
      state.isAllocationRunLoading = true;
    },

    startUnroutedLoading(state) {
      state.isUnroutedLoading = true;
    },

    startRoutedLoading(state) {
      state.isRoutedLoading = true;
    },

    startDelieveredLoading(state) {
      state.isDelieverdLoading = true;
    },

    startMandatoryLoading(state) {
      state.isMandatoryLoading = true;
    },
    startSelectedRunLoading(state) {
      state.isSelectedRunLoading = true;
    },

    startRunDetailLoading(state) {
      state.isRunDetailLoading = false;
    },

    startRunOptimiserLoading(state) {
      state.isOptimiserLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isRunDetailLoading = false;
      state.isAllocationRunLoading = false;
      state.isUnroutedLoading = false;
      state.isRoutedLoading = false;
      state.isDelieverdLoading = false;
      state.isSelectedRunLoading = false;
      state.isRunDetailLoading = false;
      state.isOptimiserLoading = false;
      state.isMandatoryLoading = false;
    },

    // GET Allocation Configuration
    getAllocationConfigurationSuccess(state, action) {
      state.isLoading = false;
      state.depots = action.payload.depots;
      state.productTypes = action.payload.productTypes;
      state.pinColoursByProductType = action.payload.pinColoursByProductType;
    },

    // GET Allocation Runs
    getAllocationRunSuccess(state, action) {
      state.isAllocationRunLoading = false;
      // state.selectedRun = null;
      // state.routedOn = true;
      state.jobRuns = action.payload.jobRuns;
    },

    // GET Unrouted Jobs
    fetchUnRoutedJobsSuccess(state, action) {
      state.isUnroutedLoading = false;
      state.unRoutedJobs = action.payload.jobs;
    },

    // GET Routed Jobs
    fetchRoutedJobsSuccess(state, action) {
      state.isRoutedLoading = false;
      state.routedJobs = action.payload.jobs;
    },

    // GET Delivered Jobs
    fetchDelieveredJobsSuccess(state, action) {
      state.isDelieverdLoading = false;
      state.deliveredJobs = action.payload.jobs;
    },

    fetchMandatoryJobsSuccess(state, action) {
      state.isMandatoryLoading = false;
      state.unRoutedJobs = action.payload.jobs;
    },

    // Clear Unrouted Jobs
    clearUnRoutedJobsSuccess(state) {
      state.isUnroutedLoading = false;
      state.unRoutedJobs = [];
    },

    // Clear Routed Jobs
    clearRoutedJobsSuccess(state) {
      state.isRoutedLoading = false;
      state.routedJobs = [];
    },

    // Clear Delievered Jobs
    clearDelieveredJobsSuccess(state) {
      state.isDelieverdLoading = false;
      state.deliveredJobs = [];
    },

    // Set Filters
    setFiltersSuccess(state, action) {
      state.filters = action.payload;
    },

    // Set Filters
    setSelectedRunSuccess(state, action) {
      state.isSelectedRunLoading = false;
      state.selectedRun = action.payload.run;
      state.selectedRunJobs = action.payload.jobs;
      state.delieveredOn = false;
      // state.unroutedOn = false;
      state.routedOn = false;
    },
    // Set job details
    setUpdatedJobDetails(state, action) {
      state.isSelectedRunLoading = false;
      state.selectedRunJobs = action.payload.jobs;
    },

    // Set Filters
    setUnSelectedRunSuccess(state) {
      state.selectedRun = null;
      state.selectedRunJobs = [];
      state.runDetail = null;
      state.unroutedOn = true;
    },

    // Set Unrouted On
    setUnroutedOnSuccess(state, action) {
      state.unroutedOn = action.payload;
    },

    // Set Unrouted On
    setRoutedOnSuccess(state, action) {
      state.routedOn = action.payload;
    },

    // Set Unrouted On
    setDelieveredOnSuccess(state, action) {
      state.delieveredOn = action.payload;
    },

    // Run details get and set
    getRunDetailsSuccess(state, action) {
      if (action.payload.jobRun.statusID == 3) {
        state.unroutedOn = false;
      } else {
        state.unroutedOn = true;
      }
      state.runDetail = action.payload;
      state.isRunDetailLoading = false;
    },
    // Stop optimiser loading
    getHandleOptimiseRun(state, action) {
      state.isOptimiserLoading = false;
    },
    //Error handling
    showMessage(state, action) {
      state.showMessage = action.payload.message;
      state.showMessageVariant = action.payload.variant;
    },

    //Error handling
    clearMessageSuccess(state) {
      state.showMessage = "";
      state.showMessageVariant = "";
    },

    refreshPageSuccess(state) {
      state.refreshPage = true;
    },

    setDrawPolygon(state, action) {
      state.drawPolygon = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllocationConfiguration() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        "/rest/api/account/job/allocation/config"
      );
      if (response.data.success) {
        dispatch(
          slice.actions.getAllocationConfigurationSuccess(response.data.data)
        );
      }
      handleMessageAndRefresh(
        response.data.success,
        response.data.showMessage,
        response.data.message,
        response.data.status
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllocationRun(unRoutedUpto: string, depotName: string) {
  return async () => {
    dispatch(slice.actions.startAllocationLoading());
    try {
      const response = await axios.get(
        `/rest/api/account/job/allocation/run?&dateSelected=${moment(
          unRoutedUpto
        ).format("YYYY-MM-DD")}&depotName=${depotName}`
      );
      if (response.data.success) {
        dispatch(slice.actions.getAllocationRunSuccess(response.data.data));
      }
      handleMessageAndRefresh(
        response.data.success,
        response.data.showMessage,
        response.data.message,
        response.data.status
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function fetchUnRoutedJobs(
  unRoutedUpto: string,
  depotName: string,
  productType: string,
  allTheLatest: boolean
) {
  return async () => {
    dispatch(slice.actions.startUnroutedLoading());
    try {
      const response = await axios.get(
        `/rest/api/account/job/allocation/unrouted?&dateSelected=${moment(
          unRoutedUpto
        ).format(
          "YYYY-MM-DD"
        )}&depotName=${depotName}&productType=${productType}&toLatest=${allTheLatest ? "yes" : "no"
        }`
      );
      if (response.data.success) {
        dispatch(slice.actions.fetchUnRoutedJobsSuccess(response.data.data));
      }
      handleMessageAndRefresh(
        response.data.success,
        response.data.showMessage,
        response.data.message,
        response.data.status
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function fetchMandatoryJobs(
  unRoutedUpto: string,
  depotName: string,
  productType: string,
  allTheLatest: boolean
) {
  return async () => {
    dispatch(slice.actions.startMandatoryLoading());
    try {
      const response = await axios.get(
        `/rest/api/account/job/allocation/mandatory?&dateSelected=${moment(
          unRoutedUpto
        ).format(
          "YYYY-MM-DD"
        )}&depotName=${depotName}&productType=${productType}`
      );
      if (response.data.success) {
        dispatch(slice.actions.fetchMandatoryJobsSuccess(response.data.data));
      }
      handleMessageAndRefresh(
        response.data.success,
        response.data.showMessage,
        response.data.message,
        response.data.status
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function clearUnRoutedJobs() {
  return async () => {
    dispatch(slice.actions.clearUnRoutedJobsSuccess());
  };
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function fetchRoutedJobs(
  unRoutedUpto: string,
  depotName: string,
  productType: string
) {
  return async () => {
    dispatch(slice.actions.startRoutedLoading());
    try {
      const response = await axios.get(
        `/rest/api/account/job/allocation/routed?dateSelected=${moment(
          unRoutedUpto
        ).format(
          "YYYY-MM-DD"
        )}&depotName=${depotName}&productType=${productType}`
      );

      if (response.data.success) {
        dispatch(slice.actions.fetchRoutedJobsSuccess(response.data.data));
      }
      handleMessageAndRefresh(
        response.data.success,
        response.data.showMessage,
        response.data.message,
        response.data.status
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function clearRoutedJobs() {
  return async () => {
    dispatch(slice.actions.clearRoutedJobsSuccess());
  };
}

// ----------------------------------------------------------------------

export function fetchDelieveredJobs(
  unRoutedUpto: string,
  depotName: string,
  productType: string
) {
  return async () => {
    dispatch(slice.actions.startDelieveredLoading());
    try {
      const response = await axios.get(
        `/rest/api/account/job/allocation/delivered?&dateSelected=${moment(
          unRoutedUpto
        ).format(
          "YYYY-MM-DD"
        )}&depotName=${depotName}&productType=${productType}`
      );
      if (response.data.success) {
        dispatch(slice.actions.fetchDelieveredJobsSuccess(response.data.data));
      }
      handleMessageAndRefresh(
        response.data.success,
        response.data.showMessage,
        response.data.message,
        response.data.status
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function clearDelieverdJobs() {
  return async () => {
    dispatch(slice.actions.clearDelieveredJobsSuccess());
  };
}

// ----------------------------------------------------------------------

export function setFilters(
  unRoutedUpto: string,
  depotName: string,
  productType: string,
  allTheLatest: boolean
) {
  return async () => {
    dispatch(
      slice.actions.setFiltersSuccess({
        unRoutedUpto,
        depotName,
        productType,
        allTheLatest,
      })
    );
  };
}

// ----------------------------------------------------------------------

export function setSelectedRun(run: any) {
  return async () => {
    dispatch(slice.actions.startSelectedRunLoading());
    try {
      const response = await axios.get(
        `rest/api/account/job/allocation/job/${run.runID} `
      );
      if (response.data.success) {
        dispatch(
          slice.actions.setSelectedRunSuccess({
            run: run,
            jobs: response.data.data.jobs,
          })
        );
      }
      handleMessageAndRefresh(
        response.data.success,
        response.data.showMessage,
        response.data.message,
        response.data.status
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setUnSelectedRun() {
  return async () => {
    dispatch(slice.actions.setUnSelectedRunSuccess());
  };
}

// ----------------------------------------------------------------------

export function setUnroutedOn(selected: boolean) {
  return async () => {
    dispatch(slice.actions.setUnroutedOnSuccess(selected));
  };
}

// ----------------------------------------------------------------------

export function setRoutedOn(selected: boolean) {
  return async () => {
    dispatch(slice.actions.setRoutedOnSuccess(selected));
  };
}

// ----------------------------------------------------------------------

export function setDelieveredOn(selected: boolean) {
  return async () => {
    dispatch(slice.actions.setDelieveredOnSuccess(selected));
  };
}

// ----------------------------------------------------------------------

export function getOperators() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/rest/api/auth/job/operator/all");
      if (response.data.success) {
        dispatch(slice.actions.getOperatorSuccess(response.data.data));
      }
      handleMessageAndRefresh(
        response.data.success,
        response.data.showMessage,
        response.data.message,
        response.data.status
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVehicles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        "/rest/api/account/job/allocation/vehicle"
      );
      if (response.data.success) {
        dispatch(slice.actions.getVehicleSuccess(response.data.data));
      }
      handleMessageAndRefresh(
        response.data.success,
        response.data.showMessage,
        response.data.message,
        response.data.status
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRunDetails(runID: string) {
  return async () => {
    dispatch(slice.actions.startRunDetailLoading());
    try {
      const response = await axios.get(
        `/rest/api/account/job/allocation/run/${runID}`
      );
      if (response.data.success) {
        dispatch(slice.actions.getRunDetailsSuccess(response.data.data));
      }
      handleMessageAndRefresh(
        response.data.success,
        response.data.showMessage,
        response.data.message,
        response.data.status
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateRunDetails(runDetail: any) {
  dispatch(slice.actions.getRunDetailsSuccess(runDetail));
}

// ----------------------------------------------------------------------

export function handleOptimiseRun(
  runID: string,
  unrouted: string,
  depotName: string
) {
  return async () => {
    dispatch(slice.actions.startRunOptimiserLoading());
    try {
      const url = `/rest/api/account/job/allocation/run/optimiser`;
      const body = {
        runID: runID,
      };
      const response = await axios.post(url, body);
      if (response.data.success) {
        dispatch(slice.actions.getHandleOptimiseRun(response.data.data));
        dispatch(getAllocationRun(unrouted, depotName));
        dispatch(getRunDetails(runID));
        dispatch(getUpdatedJobDetails(runID));
      }
      handleMessageAndRefresh(
        response.data.success,
        response.data.showMessage,
        response.data.message,
        response.data.status
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUpdatedJobDetails(runID: any) {
  return async () => {
    dispatch(slice.actions.startSelectedRunLoading());
    try {
      const response = await axios.get(
        `rest/api/account/job/allocation/job/${runID} `
      );
      if (response.data.success) {
        dispatch(
          slice.actions.setUpdatedJobDetails({ jobs: response.data.data.jobs })
        );
      }
      handleMessageAndRefresh(
        response.data.success,
        response.data.showMessage,
        response.data.message,
        response.data.status
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function handleSendJobsToOperator(
  runID: any,
  unrouted: string,
  depotName: string
) {
  return async () => {
    dispatch(slice.actions.startRunOptimiserLoading());
    try {
      const url = `/rest/api/account/job/allocation/run/send/mobile`;
      const body = {
        runID: runID,
      };
      const response = await axios.post(url, body);
      if (response.data.success) {
        dispatch(slice.actions.getHandleOptimiseRun(response.data.data));
        dispatch(getRunDetails(runID));
        dispatch(getAllocationRun(unrouted, depotName));
        dispatch(setDrawPolygon(false));
      }

      handleMessageAndRefresh(
        response.data.success,
        response.data.showMessage,
        response.data.message,
        response.data.status
      );

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function clearMessage() {
  return async () => {
    dispatch(slice.actions.clearMessageSuccess());
  };
}

export function handleMessageAndRefresh(
  success: any,
  showMessage: any,
  message: any,
  status: any
) {
  if (showMessage) {
    dispatch(
      slice.actions.showMessage({
        message: message,
        variant: success ? "success" : "error",
      })
    );
  }
  if (!success) {
    if (status == 403 || status == 401) {
      dispatch(slice.actions.refreshPageSuccess());
    } else {
      dispatch(slice.actions.hasError("Failed"));
    }
  }
}

export function setDrawPolygon(value: boolean) {
  return async () => {
    dispatch(slice.actions.setDrawPolygon(value));
  };
}